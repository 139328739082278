import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Drawer, Grid, IconButton, List, ListItem, ListItemText, ThemeProvider, Toolbar, Typography, createTheme, useMediaQuery } from '@mui/material';
import { Container, ISourceOptions } from '@tsparticles/engine';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import { loadSlim } from '@tsparticles/slim';
import { useEffect, useMemo, useState } from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import "./App.css";
import About from './components/About';
import Honeymoon from './components/Honeymoon';
import Party from './components/Party';
import Rsvp from './components/Rsvp';
import Stay from './components/Stay';
import Welcome from './components/Welcome';
import Food from './components/Food';
import GuestSummary from "./components/GuestSummary";

const App = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#002855',
      },
      secondary: {
        main: '#F7E7CE'
      }
    },
    typography: {
      h1: {
        fontFamily: 'DisneyFont',
        letterSpacing: '5px',
        fontWeight: 'bold',
        backgroundImage: `linear-gradient(
          to right,
          #cb9b51 0%,
          #f6e27a 45%,
          #f6f2c0 50%,
          #f6e27a 55%,
          #cb9b51 78%,
          #462523 100%
        )`,
        color: 'transparent',
        WebkitBackgroundClip: 'text',
      },
      h2: {
        fontFamily: 'DisneyFont',
        letterSpacing: '5px',
        fontWeight: 'bold',
        backgroundImage: `linear-gradient(
          to right,
          #cb9b51 0%,
          #f6e27a 45%,
          #f6f2c0 50%,
          #f6e27a 55%,
          #cb9b51 78%,
          #462523 100%
        )`,
        color: 'transparent',
        WebkitBackgroundClip: 'text',
      },
      h3: {
        fontFamily: 'DisneyFont',
        letterSpacing: '5px',
        fontWeight: 'bold',
        backgroundImage: `linear-gradient(
          to right,
          #cb9b51 0%,
          #f6e27a 45%,
          #f6f2c0 50%,
          #f6e27a 55%,
          #cb9b51 78%,
          #462523 100%
        )`,
        color: 'transparent',
        WebkitBackgroundClip: 'text',
      },
      h5: {
          fontFamily: 'DisneyFont',
          letterSpacing: '5px',
          fontWeight: 'bold',
          backgroundImage: `linear-gradient(
            to right,
            #cb9b51 0%,
            #f6e27a 45%,
            #f6f2c0 50%,
            #f6e27a 55%,
            #cb9b51 78%,
            #462523 100%
          )`,
          color: 'transparent',
          WebkitBackgroundClip: 'text',
      },
      body1: {
        fontFamily: 'DisneyFont',
        fontSize: '29px',
      },
      body2: {
        fontSize: '22px',
      }
    }
  });


  const [drawerOpen, setDrawerOpen] = useState(false);

  const location = useLocation();

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = async (container?: Container): Promise<void> => {
    console.log(container);
  };

  const options: ISourceOptions = useMemo(
    () => ({
      fpsLimit: 120,
      particles: {
        zIndex: {
          value: 1
        },
        color: {
          value: "#fff"
        },
        number: {
          density: {
            enable: true,
          },
          value: 500,
        },
        opacity: {
          value: {
            min: 0,
            max: 1
          },
          random: true, // Enable random opacity for each particle
          animation: {
            enable: true,
            speed: 2,
            delay: 0,
            decay: 0,
            startValue: "random",
            mode: "auto",
          }
        },
        shape: {
          type: "star",
        },
        size: {
          value: { min: 1, max: 3 },
          random: true, // Enable random size for each particle
        },
        move: {
          enable: true,
          speed: 0.1,
        }
      },
      detectRetina: true,
    }),
    [],
  );

  const isSmallDevice = useMediaQuery('(max-width: 600px)');

  return (
    <ThemeProvider theme={theme}>
      {init && <Particles
        id="tsparticles"
        particlesLoaded={particlesLoaded}
        options={options}
      />}
      <div className="App">
        <AppBar position="static" elevation={0}>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          anchor="top"
          open={drawerOpen}
          sx={{textAlign: "center"}}
          PaperProps={{sx: {background: "rgba(0,40,85,0.85)", width: "100%", height: "auto", textAlign: "center", alignItems: "center"}}}
          onClose={handleDrawerClose}
        >
          <List>
            <Link style={{textDecoration: "none"}} to="/">
              <ListItem onClick={handleDrawerClose}>
                <ListItemText     primaryTypographyProps={{fontSize: isSmallDevice? "25px" : "40px", textAlign: "center"}} 
 className="drawer-link" primary="Welcome" />
              </ListItem>
            </Link>
            <Link style={{textDecoration: "none"}} to="/about">
              <ListItem onClick={handleDrawerClose}>
                <ListItemText primaryTypographyProps={{fontSize: isSmallDevice? "25px" : "40px", textAlign: "center"}} className="drawer-link"primary="Once upon a time..." />
              </ListItem>
            </Link>
            <Link style={{textDecoration: "none"}} to="party">
              <ListItem onClick={handleDrawerClose}>
                <ListItemText primaryTypographyProps={{fontSize: isSmallDevice? "25px" : "40px",textAlign: "center"}}  className="drawer-link"primary="Royal Entourage" />
              </ListItem>
            </Link>
            <Link style={{textDecoration: "none"}} to="/rsvp"><ListItem onClick={handleDrawerClose}>
              <ListItemText primaryTypographyProps={{fontSize: isSmallDevice? "25px" : "40px",textAlign: "center"}}  className="drawer-link"primary="You Are Cordially Invited" />
            </ListItem>
            </Link>
            <Link style={{textDecoration: "none"}} to="/food">
              <ListItem onClick={handleDrawerClose}>
                <ListItemText primaryTypographyProps={{fontSize: isSmallDevice? "25px" : "40px", textAlign: "center"}}  className="drawer-link" primary="Enchanted Feast" />
              </ListItem>
            </Link>
            <Link style={{textDecoration: "none"}} to="/stay">
              <ListItem onClick={handleDrawerClose}>
                <ListItemText primaryTypographyProps={{fontSize: isSmallDevice? "25px" : "40px", textAlign: "center"}}  className="drawer-link" primary="Fairy Tale Lodgings" />
              </ListItem>
            </Link>
            <Link style={{textDecoration: "none"}} to="/honeymoon">
              <ListItem onClick={handleDrawerClose}>
                <ListItemText primaryTypographyProps={{fontSize: isSmallDevice? "25px" : "40px",textAlign: "center"}}  className="drawer-link" primary="Happily Ever After Honeymoon" />
              </ListItem>
            </Link>
          </List>
        </Drawer>    <div style={{ position: "relative", zIndex: 1 }}><Routes>

          <Route path="/" Component={Welcome} />
          <Route path="/about" Component={About} />
          <Route path="/party" Component={Party} />
          <Route path="/rsvp" Component={Rsvp} />
          <Route path="/food" Component={Food} />
          <Route path="/stay" Component={Stay} />
          <Route path="/honeymoon" Component={Honeymoon} />
          <Route path="/guests" Component={GuestSummary} />


        </Routes></div>
       
      </div >
      <Box
      sx={{
        width: "100%",
        height: "auto",
        paddingTop: "1rem",
        paddingBottom: "1rem",
      }}
    >
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12}></Grid>
        {location.pathname === "/" ? (
          <Grid item xs={12}>
            <Typography color="white" fontFamily={"DisneyFont"} fontSize={"22px"} variant="subtitle1">
              Made by Ryan Sutcliffe
            </Typography>
          </Grid>
        ):(  <Typography color="white" fontFamily={"DisneyFont"} fontSize={"22px"} variant="subtitle1">
        21st June 2025
      </Typography>)}
      </Grid>
    </Box>
    </ThemeProvider >
  );
};

export default App;
