import { Button, Card, CardContent, Container, Typography } from "@mui/material";
import React, { useState } from "react";
import FoodChoiceForm from "./FoodChoiceForm";

const Food = () => {
    const [showFoodForm, setShowFoodForm] = useState(false);

    const toggleFoodForm = () => {
        setShowFoodForm(!showFoodForm);
    };

    return (
        <><Container maxWidth="md" style={{ marginTop: '40px', textAlign: 'center' }}>
            <Card style={{background: "rgba(1000,1000,1000,0.2)", color: "white"}}>
                <CardContent>
                    <Typography variant="h3" gutterBottom>
                        Enchanted Feast
                    </Typography>
                    <Typography variant="body2">
                        <CardContent>
                            <Typography variant="body2">
                                Select your meal preferences for our magical feast. Every guest has the opportunity to choose from a variety of dishes to ensure everyone's taste is catered for. Please note any dietary restrictions or preferences here.
                            </Typography>
                            <div>
                                <br />
                                <Button variant="contained" sx={{fontSize: "38px", fontFamily: "DisneyFont", letterSpacing: "5px"}} onClick={toggleFoodForm}>Select Meals</Button>
                                <FoodChoiceForm open={showFoodForm} onClose={toggleFoodForm} />
                            </div>
                        </CardContent>
                    </Typography>
                </CardContent>
            </Card>
        </Container></>
    );
};

export default Food;