import React, { useState } from 'react';
import { Button, Card, CardContent, Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";

const GuestSummary = () => {
    const [summary, setSummary] = useState({
        ceremonyCount: 0,
        eveningCount: 0,
        childrenCeremonyCount: 0,
        childrenEveningCount: 0,
        adultsCeremonyCount: 0,
        adultsEveningCount: 0
    });

    const handleGetGuestList = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let apiData;

        try {
            // Simulating an API call with the provided data

                const apiResponse = await fetch('https://2euuygd2kkfe3o6bgv4eyg6khi0uksgg.lambda-url.eu-west-2.on.aws/data');
                if (apiResponse.ok) {
                    apiData = await apiResponse.json();
                }


            let newSummary = {
                ceremonyCount: 0,
                eveningCount: 0,
                childrenCeremonyCount: 0,
                childrenEveningCount: 0,
                adultsCeremonyCount: 0,
                adultsEveningCount: 0
            };

            for (const email in apiData) {
                if (apiData.hasOwnProperty(email)) {
                    const guests = apiData[email].guests || [];
                    guests.forEach((guest: any) => {
                        const isChild = guest.child.toLowerCase() === "yes";
                        if (guest.ceremony.toLowerCase() === "yes") {
                            newSummary.ceremonyCount++;
                            if (isChild) newSummary.childrenCeremonyCount++;
                            else newSummary.adultsCeremonyCount++;
                        }
                        if (guest.evening.toLowerCase() === "yes") {
                            newSummary.eveningCount++;
                            if (isChild) newSummary.childrenEveningCount++;
                            else newSummary.adultsEveningCount++;
                        }
                    });
                }
            }

            setSummary(newSummary);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (
        <Container maxWidth="md" style={{ marginTop: '40px', textAlign: 'center' }}>
            <Card sx={{ background: "rgba(1000,1000,1000,0.2)", color: "white" }}>
                <CardContent>
                    <Typography variant="h3" gutterBottom>
                        Guest Attendance Summary
                    </Typography>
                    <Typography variant="body2">
                        <CardContent>
                            <Typography variant="body2">
                                Click to see the summary of guest attendance for the wedding.
                            </Typography>
                            <form onSubmit={handleGetGuestList}>
                                <Button type="submit" variant="contained" sx={{fontSize: "38px", fontFamily: "DisneyFont", letterSpacing: "5px"}}>
                                    Show Summary
                                </Button>
                            </form>
                            <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Category</TableCell>
                                            <TableCell align="right">Count</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.entries(summary).map(([key, value]) => (
                                            <TableRow key={key}>
                                                <TableCell component="th" scope="row">
                                                    {key.split(/(?=[A-Z])/).map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                                </TableCell>
                                                <TableCell align="right">{value}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Typography>
                </CardContent>
            </Card>
        </Container>
    );
};

export default GuestSummary;