import { Alert, Backdrop, Box, Button, CircularProgress, FormControl, FormControlLabel, FormLabel, Modal, Radio, RadioGroup, TextField, Typography, Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { useState } from 'react';

interface Guest {
    name: string;
    child: boolean;
    ceremony: boolean;
    evening: boolean;
}
interface FoodFormData {
    email: string;
    guests: {
        guest: Guest;
        starter: string;
        main: string;
        dessert: string;
    }[];
}

interface Props {
    open: boolean;
    onClose: () => void;
}

interface ImageModalProps {
    open: boolean;
    onClose: () => void;
    imageSrc: string;
}

const ImageModal: React.FC<ImageModalProps> = ({ open, onClose, imageSrc }) => (
    <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="food-image-dialog-title"
    >
        <DialogContent>
            <img src={imageSrc} alt="Food" style={{ maxWidth: '100%', maxHeight: '400px' }} />
        </DialogContent>
    </Dialog>
);

function FoodChoiceForm({ open, onClose }: Props) {
    const [formData, setFormData] = useState<FoodFormData>({
        email: '',
        guests: [],
    });

    const [submitStatus, setSubmitStatus] = useState<'success' | 'error' | 'rsvp' | 'evening' | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [guestLoading, setGuestLoading] = useState<boolean>(false);
    const [imageModalOpen, setImageModalOpen] = useState<boolean>(false);
    const [currentImage, setCurrentImage] = useState<string>('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleGetGuestList = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setGuestLoading(true);
        setSubmitStatus(null);
        setFormData({
            email: formData.email.toLowerCase(),
            guests: [],
        });

        try {
            const apiResponse = await fetch('https://2euuygd2kkfe3o6bgv4eyg6khi0uksgg.lambda-url.eu-west-2.on.aws/data');
            if (apiResponse.ok) {
                const apiData = await apiResponse.json();
                const apiParty = apiData[formData.email.toLowerCase()];
                if (apiParty) {
                    const initialGuests = apiParty.guests?.map((guest: any) => ({
                        guest: {
                            name: guest.name,
                            child: guest.child.toLowerCase() === "yes",
                            ceremony: guest.ceremony === "yes",
                            evening: guest.evening === "yes"
                        },
                        starter: guest.starter || '',
                        main: guest.main || '',
                        dessert: guest.dessert || '',
                    })) || [];

                    setFormData({
                        ...formData,
                        guests: initialGuests,
                    });

                    // Check if all guests are evening only
                    const allEvening = initialGuests.every((g: any) => g.guest.evening && !g.guest.ceremony);
                    if (allEvening) {
                        setSubmitStatus('evening');
                    }

                    setGuestLoading(false);
                    return;
                } else {
                    setGuestLoading(false);
                    setSubmitStatus('rsvp');
                    return;
                }
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }

        setGuestLoading(false);
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const response = await fetch('https://2euuygd2kkfe3o6bgv4eyg6khi0uksgg.lambda-url.eu-west-2.on.aws/add-food', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: formData.email.toLowerCase(),
                    guests: formData.guests.map(guest => ({
                        name: guest.guest.name,
                        starter: guest.starter,
                        main: guest.main,
                        dessert: guest.dessert,
                    })),
                }),
            });

            if (response.ok) {
                setSubmitStatus('success');
                onClose(); // Close modal on successful submission
            } else {
                setSubmitStatus('error');
            }
        } catch (error) {
            console.error('Error submitting food choices:', error);
            setSubmitStatus('error');
        }
        setLoading(false);
    };

    const handleCourseChoiceChange = (guest: string, course: 'starter' | 'main' | 'dessert', value: string) => {
        const updatedGuests = formData.guests.map(g =>
            g.guest.name === guest ? { ...g, [course]: value } : g
        );
        setFormData({
            ...formData,
            guests: updatedGuests,
        });
    };

    const handleImageClick = (imageUrl: string) => {
        setCurrentImage(imageUrl);
        setImageModalOpen(true);
    };

    const closeImageModal = () => {
        setImageModalOpen(false);
    };

    return (
        <>
            <Modal
                open={open}
                onClose={onClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Box sx={{
                    position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
                    maxWidth: 600, width: "90%", bgcolor: 'background.paper', boxShadow: 24, p: 4,
                    overflowY: 'auto', maxHeight: '80vh',
                    '@media (max-width: 600px)': { width: "95%", fontSize: '16px' }
                }}>
                    <form onSubmit={handleGetGuestList}>
                        <TextField
                            fullWidth
                            id="email"
                            name="email"
                            label="Enter your email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                        <Box display="flex">
                            <Button type="submit" variant="contained" sx={{ mt: 2, mr: 1 }}>Get Guest List</Button>
                            {guestLoading && <CircularProgress sx={{ mt: 2, marginLeft: "10px" }} />}
                        </Box>
                    </form>

                    {submitStatus === 'success' && (
                        <Alert severity="success" sx={{ mt: 2 }}>Food choices submitted successfully.</Alert>
                    )}
                    {submitStatus === 'error' && (
                        <Alert severity="error" sx={{ mt: 2 }}>Failed to submit food choices. Please try again later.</Alert>
                    )}
                    {submitStatus === 'rsvp' && (
                        <Alert severity="error" sx={{ mt: 2 }}>You must RSVP first before continuing.</Alert>
                    )}
                    {submitStatus === 'evening' && (
                        <Alert severity="error" sx={{ mt: 2 }}>Sorry but you have been invited to the evening only.</Alert>
                    )}

                    {formData.guests.length > 0 && (
                        <Box mt={2}>
                            {formData.guests.filter(g => g.guest.child).length > 0 && (<><Typography>Children's Set
                                Menu:</Typography><Box
                                sx={{border: "1px solid gold", borderRadius: "10px", padding: "5px"}}>
                                <Typography sx={{fontSize: '18px'}}>Starter: Garlic Bread with Cheese</Typography>
                                <Typography sx={{fontSize: '18px'}}>Main: Chicken Bites, Chips & Peas</Typography>
                                <Typography sx={{fontSize: '18px'}}>Dessert: Chocolate Brownie and Vanilla Ice
                                    Cream</Typography>
                            </Box></>)}
                            {formData.guests.filter(g => !g.guest.child && g.guest.ceremony).length > 0 && <Typography>Choose Meal for Each Adult Guest:</Typography>}
                            <ul>
                                {formData.guests.filter(g => !g.guest.child && g.guest.ceremony).map(({ guest, starter, main, dessert }) => (
                                    <li key={guest.name}>
                                        <Typography>{guest.name}</Typography>
                                        <FormControl component="fieldset" sx={{ mt: 1 }}>
                                            <FormLabel component="legend" sx={{ fontSize: '16px' }}>Starter</FormLabel>
                                            <RadioGroup
                                                aria-label="starter-choice"
                                                name={`${guest}-starter`}
                                                value={starter}
                                                onChange={(event) => handleCourseChoiceChange(guest.name, 'starter', event.target.value)}
                                            >
                                                <FormControlLabel value="soup" control={<Radio />} label={
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, border: "1px solid gold", borderRadius: "10px", padding: "5px" }}>
                                                        <Typography sx={{ fontSize: '16px' }}>Leek & Potatoe Soup (VG, GF)</Typography>
                                                        
                                                    </Box>
                                                } />
                                                <FormControlLabel value="fishcake" control={<Radio />} label={
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, border: "1px solid gold", borderRadius: "10px", padding: "5px" }}>
                                                        <Typography sx={{ fontSize: '16px' }}>Smoked Haddock, Prawn and Chive Fish Cake (GFA) with Caviar Tartare and Samphire Grass</Typography>
                                                        
                                                    </Box>
                                                } />
                                                <FormControlLabel value="langoustine" control={<Radio />} label={
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, border: "1px solid gold", borderRadius: "10px", padding: "5px" }}>
                                                        <Typography sx={{ fontSize: '16px' }}>Poached Langoustine (GFA), Califlour Pannecote, Pickled Wild Mushrooms, Nasturtiums, Sauce Jacqueline</Typography>
                                                        <Button size="small" onClick={() => handleImageClick("./images/food/langoustine.jpg")}>View Image</Button>
                                                    </Box>
                                                } />
                                            </RadioGroup>
                                        </FormControl>
                                        <br/>
                                        <FormControl component="fieldset" sx={{ mt: 1 }}>
                                            <FormLabel component="legend" sx={{ fontSize: '16px' }}>Main</FormLabel>
                                            <RadioGroup
                                                aria-label="main-choice"
                                                name={`${guest}-main`}
                                                value={main}
                                                onChange={(event) => handleCourseChoiceChange(guest.name, 'main', event.target.value)}
                                            >
                                                <FormControlLabel value="steak" control={<Radio />} label={
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, border: "1px solid gold", borderRadius: "10px", padding: "5px" }}>
                                                        <Typography sx={{ fontSize: '16px' }}>Pave Steak, Duphinoise Potatoe, Butternut, Shallot, Wild Mushroom, Beef Jus</Typography>
                                                        
                                                    </Box>
                                                } />
                                                <FormControlLabel value="chicken" control={<Radio />} label={
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, border: "1px solid gold", borderRadius: "10px", padding: "5px" }}>
                                                        <Typography sx={{ fontSize: '16px' }}>Pan-Fried Chicken Breast, Crispy Potatoe Cake, Heritage Carrots, Seasonal Greens, Chicken Butter Sauce</Typography>
                                                        <Button size="small" onClick={() => handleImageClick("./images/food/chicken.jpg")}>View Image</Button>
                                                    </Box>
                                                } />
                                                <FormControlLabel value="seabass" control={<Radio />} label={
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, border: "1px solid gold", borderRadius: "10px", padding: "5px" }}>
                                                        <Typography sx={{ fontSize: '16px' }}>Sea bass Fillet (GFA), Crushed New Potatoes, Preserved Lemon, Samphire, Tenderstem Broccoli</Typography>
                                                        
                                                    </Box>
                                                } />
                                            </RadioGroup>
                                        </FormControl>
                                        <br/>
                                        <FormControl component="fieldset" sx={{ mt: 1 }}>
                                            <FormLabel component="legend" sx={{ fontSize: '16px' }}>Dessert</FormLabel>
                                            <RadioGroup
                                                aria-label="dessert-choice"
                                                name={`${guest}-dessert`}
                                                value={dessert}
                                                onChange={(event) => handleCourseChoiceChange(guest.name, 'dessert', event.target.value)}
                                            >
                                                <FormControlLabel value="creme" control={<Radio />} label={
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, border: "1px solid gold", borderRadius: "10px", padding: "5px" }}>
                                                        <Typography sx={{ fontSize: '16px' }}>Classic Vanilla Bean Creme Brulee (GFA), Lavender Shortbread Fingers</Typography>
                                                        <Button size="small" onClick={() => handleImageClick("./images/food/creme.jpg")}>View Image</Button>
                                                    </Box>
                                                } />
                                                <FormControlLabel value="cheesecake" control={<Radio />} label={
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, border: "1px solid gold", borderRadius: "10px", padding: "5px" }}>
                                                        <Typography sx={{ fontSize: '16px' }}>Strawberry Cheesecake (GFA), Crisp Meringue, Basil Syrup</Typography>
                                                        
                                                    </Box>
                                                } />
                                                <FormControlLabel value="ganache" control={<Radio />} label={
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, border: "1px solid gold", borderRadius: "10px", padding: "5px" }}>
                                                        <Typography sx={{ fontSize: '16px' }}>Salted Caramel and Dark Chocolate Ganache, Hazelnut Praline Brittle</Typography>
                                                        <Button size="small" onClick={() => handleImageClick("./images/food/ganache.jpg")}>View Image</Button>
                                                    </Box>
                                                } />
                                            </RadioGroup>
                                        </FormControl>
                                    </li>
                                ))}
                            </ul>
                            {formData.guests.filter(g => !g.guest.child && g.guest.ceremony).length > 0 &&  (<Button
                                variant="contained"
                                onClick={handleSubmit}
                                sx={{ mt: 2 }}
                            >
                                Submit Food Choices
                            </Button>) }
                            {loading && <CircularProgress sx={{ mt: 2, marginLeft: "10px" }} />}
                        </Box>
                    )}
                </Box>
            </Modal>
            <ImageModal open={imageModalOpen} onClose={closeImageModal} imageSrc={currentImage} />
        </>
    );
}

export default FoodChoiceForm;